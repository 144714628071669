// VUEX DYNAMIC STORE MODULE
import {
  getReportCosts,
  getExportCosts,
  getStreamCosts,
  getReportBroadcastReference,
  getExportBroadcastReference,
  getReportObligationExecutions,
  getExportObligationExecutions,
  getReportClientsAdvertisers,
  getExportClientsAdvertisers,
  getExportSalesPlanExecutions,
  getReportSalesPlanExecutions,
  putCalendarPlans,
  getExportInventory,
  getReportInventory,
  getReportPrograms,
  getExportPrograms,
  getReportByProjects,
  getExportByProjects,
  getReportByProjectsShort,
  getExportByProjectsShort,
  getReportInventoryExtended,
  getExportInventoryExtended,
  getReportCategorySummary,
  getExportCategorySummary,
  getExportMMRKazakhstan,
  getReportMMRKazakhstan,
  getReportSalesPlanExecutionsTVNetwork,
  getExportSalesPlanExecutionsTVNetwork,
} from '../../api/apiServices';

export default {
  state: {
    report: {
      costs: '',
      costsStatus: '',
      broadcastReference: '',
      broadcastReferenceStatus: '',
      obligationExecutions: '',
      obligationExecutionsStatus: '',
      clientsAdvertisers: '',
      clientsAdvertisersStatus: '',
      salesPlanExecutions: '',
      salesPlanExecutionsStatus: '',
      salesPlanExecutionsTVNetwork: '',
      salesPlanExecutionsTVNetworkStatus: '',
      inventory: '',
      inventoryStatus: '',
      programs: '',
      programsStatus: '',
      byProjects: '',
      byProjectsStatus: '',
      byProjectsShort: '',
      byProjectsShortStatus: '',
      inventoryExtended: '',
      inventoryExtendedStatus: '',
      categorySummary: '',
      categorySummaryStatus: '',
      mmrKazakhstan: '',
      mmrKazakhstanStatus: '',
    },
    expandAllRows: false,
  },
  mutations: {
    //costs
    setReportCostsRequest: (state) => (state.report.costsStatus = 'loading'),
    setReportCostsSuccess: (state, res) => {
      state.report.costsStatus = 'success';
      state.report.costs = res;
    },
    setReportCostsError: (state) => (state.report.costsStatus = 'error'),
    clearReportCosts: (state) => {
      state.report.costsStatus = '';
      state.report.costs = '';
    },

    //broadcastReference
    setReportBroadcastReferenceRequest: (state) => (state.report.broadcastReferenceStatus = 'loading'),
    setReportBroadcastReferenceSuccess: (state, res) => {
      state.report.broadcastReferenceStatus = 'success';
      let temp = res;
      for (let i of Object.keys(res)) {
        if (res[i]?.items) {
          temp[i].items.forEach((el, index) => (el.index = index + 1));
        }
      }
      state.report.broadcastReference = temp;
    },
    setReportBroadcastReferenceError: (state) => (state.report.broadcastReferenceStatus = 'error'),
    clearReportBroadcastReference: (state) => {
      state.report.broadcastReferenceStatus = '';
      state.report.broadcastReference = '';
    },

    //obligationExecutions
    setReportObligationExecutionsRequest: (state) => (state.report.obligationExecutionsStatus = 'loading'),
    setReportObligationExecutionsSuccess: (state, res) => {
      state.report.obligationExecutionsStatus = 'success';
      state.report.obligationExecutions = res;
    },
    setReportObligationExecutionsError: (state) => (state.report.obligationExecutionsStatus = 'error'),
    clearReportObligationExecutions: (state) => {
      state.report.obligationExecutionsStatus = '';
      state.report.obligationExecutions = '';
    },

    //clients-advertisers
    setReportClientsAdvertisersRequest: (state) => (state.report.clientsAdvertisersStatus = 'loading'),
    setReportClientsAdvertisersSuccess: (state, res) => {
      state.report.clientsAdvertisersStatus = 'success';
      state.report.clientsAdvertisers = res;
    },
    setReportClientsAdvertisersError: (state) => (state.report.clientsAdvertisersStatus = 'error'),
    clearReportClientsAdvertisers: (state) => {
      state.report.clientsAdvertisersStatus = '';
      state.report.clientsAdvertisers = '';
    },

    //SalesPlanExecutions
    setReportSalesPlanExecutionsRequest: (state) => (state.report.salesPlanExecutionsStatus = 'loading'),
    setReportSalesPlanExecutionsSuccess: (state, res) => {
      state.report.salesPlanExecutionsStatus = 'success';
      state.report.salesPlanExecutions = res;
    },
    setReportSalesPlanExecutionsError: (state) => (state.report.salesPlanExecutionsStatus = 'error'),
    clearReportSalesPlanExecutions: (state) => {
      state.report.salesPlanExecutionsStatus = '';
      state.report.salesPlanExecutions = '';
    },

    //SalesPlanExecutionsTVNetwork
    setReportSalesPlanExecutionsTVNetworkRequest: (state) => (state.report.salesPlanExecutionsTVNetworkStatus = 'loading'),
    setReportSalesPlanExecutionsTVNetworkSuccess: (state, res) => {
      state.report.salesPlanExecutionsTVNetworkStatus = 'success';
      state.report.salesPlanExecutionsTVNetwork = res;
    },
    setReportSalesPlanExecutionsTVNetworkError: (state) => (state.report.salesPlanExecutionsTVNetworkStatus = 'error'),
    clearReportSalesPlanExecutionsTVNetwork: (state) => {
      state.report.salesPlanExecutionsTVNetworkStatus = '';
      state.report.salesPlanExecutionsTVNetwork = '';
    },

    //Inventory
    setReportInventoryRequest: (state) => (state.report.inventoryStatus = 'loading'),
    setReportInventorySuccess: (state, res) => {
      state.report.inventoryStatus = 'success';
      const tempInventory = res;
      for (let i of Object.keys(res)) {
        for (let grIndex in res[i].items) {
          tempInventory[i].items[grIndex].visible = state.expandAllRows; // for toggle group rows visibility
        }
      }
      state.report.inventory = tempInventory;
      console.log(state.report.inventory);
    },
    setReportInventoryError: (state) => (state.report.inventoryStatus = 'error'),
    clearReportInventory: (state) => {
      state.report.inventoryStatus = '';
      state.report.inventory = '';
      state.expandAllRows = false;
    },

    //ByProjects
    setReportByProjectsRequest: (state) => (state.report.byProjectsStatus = 'loading'),
    setReportByProjectsSuccess: (state, res) => {
      state.report.byProjectsStatus = 'success';
      state.report.byProjects = res;
    },
    setReportByProjectsError: (state) => (state.report.byProjectsStatus = 'error'),
    clearReportByProjects: (state) => {
      state.report.byProjectsStatus = '';
      state.report.byProjects = '';
    },

    //ByProjects (short)
    setReportByProjectsShortRequest: (state) => (state.report.byProjectsShortStatus = 'loading'),
    setReportByProjectsShortSuccess: (state, res) => {
      state.report.byProjectsShortStatus = 'success';
      state.report.byProjectsShort = res;
    },
    setReportByProjectsShortError: (state) => (state.report.byProjectsShortStatus = 'error'),
    clearReportByProjectsShort: (state) => {
      state.report.byProjectsShortStatus = '';
      state.report.byProjectsShort = '';
    },

    //Programs
    setReportProgramsRequest: (state) => (state.report.programsStatus = 'loading'),
    setReportProgramsSuccess: (state, res) => {
      state.report.programsStatus = 'success';
      const tempPrograms = res;
      for (let grIndex in res.items) {
        tempPrograms.items[grIndex].visible = state.expandAllRows; // for toggle group rows visibility
      }
      state.report.programs = tempPrograms;
    },
    setReportProgramsError: (state) => (state.report.programsStatus = 'error'),
    clearReportPrograms: (state) => {
      state.report.programsStatus = '';
      state.report.programs = '';
    },

    //Inventory Extended
    setReportInventoryExtendedRequest: (state) => (state.report.inventoryExtendedStatus = 'loading'),
    setReportInventoryExtendedSuccess: (state, res) => {
      state.report.inventoryExtendedStatus = 'success';
      state.report.inventoryExtended = res;
    },
    setReportInventoryExtendedError: (state) => (state.report.inventoryExtendedStatus = 'error'),
    clearReportInventoryExtended: (state) => {
      state.report.inventoryExtendedStatus = '';
      state.report.inventoryExtended = '';
    },

    //Category summary
    setReportCategorySummaryRequest: (state) => (state.report.categorySummaryStatus = 'loading'),
    setReportCategorySummarySuccess: (state, res) => {
      state.report.categorySummaryStatus = 'success';
      state.report.categorySummary = res;
    },
    setReportCategorySummaryError: (state) => (state.report.categorySummaryStatus = 'error'),
    clearReportCategorySummary: (state) => {
      state.report.categorySummaryStatus = '';
      state.report.categorySummary = '';
    },

    //MMR Kazakhstan
    setReportMMRKazakhstanRequest: (state) => (state.report.mmrKazakhstanStatus = 'loading'),
    setReportMMRKazakhstanSuccess: (state, res) => {
      state.report.mmrKazakhstanStatus = 'success';
      state.report.mmrKazakhstan = res;
    },
    setReportMMRKazakhstanError: (state) => (state.report.mmrKazakhstanStatus = 'error'),
    clearReportMMRKazakhstan: (state) => {
      state.report.mmrKazakhstanStatus = '';
      state.report.mmrKazakhstan = '';
    },

    toggleExpandAllTableRows: (state) => (state.expandAllRows = !state.expandAllRows),
  },
  actions: {
    //costs
    async GET_REPORT_COSTS({ dispatch, commit }, payload) {
      commit('setReportCostsRequest');
      await getReportCosts(payload)
        .then((res) => {
          commit('setReportCostsSuccess', res.data.data);
        })
        .catch((e) => {
          commit('setReportCostsError');
        });
    },
    async GET_EXPORT_REPORT_COSTS({ dispatch, commit }, payload) {
      await getExportCosts(payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
    async GET_EXPORT_STREAM_COSTS({ dispatch, commit }, payload) {
      await getStreamCosts(payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },

    //broadcastReference
    async GET_REPORT_BROADCAST_REFERENCE({ dispatch, commit }, payload) {
      commit('setReportBroadcastReferenceRequest');
      await getReportBroadcastReference(payload)
        .then((res) => {
          commit('setReportBroadcastReferenceSuccess', res.data.data);
        })
        .catch((e) => {
          commit('setReportBroadcastReferenceError');
        });
    },
    async GET_EXPORT_REPORT_BROADCAST_REFERENCE({ dispatch, commit }, payload) {
      await getExportBroadcastReference(payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },

    //obligationExecutions
    async GET_REPORT_OBLIGATION_EXECUTIONS({ dispatch, commit }, payload) {
      commit('setReportObligationExecutionsRequest');
      await getReportObligationExecutions(payload)
        .then((res) => {
          commit('setReportObligationExecutionsSuccess', res.data.data);
        })
        .catch((e) => {
          commit('setReportObligationExecutionsError');
        });
    },
    async GET_EXPORT_REPORT_OBLIGATION_EXECUTIONS({ dispatch, commit }, payload) {
      await getExportObligationExecutions(payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },

    //clients-advertisers
    async GET_REPORT_CLIENTS_ADVERTISERS({ dispatch, commit }, payload) {
      commit('setReportClientsAdvertisersRequest');
      await getReportClientsAdvertisers(payload)
        .then((res) => {
          commit('setReportClientsAdvertisersSuccess', res.data.data);
        })
        .catch((e) => {
          commit('setReportClientsAdvertisersError');
        });
    },
    async GET_EXPORT_REPORT_CLIENTS_ADVERTISERS({ dispatch, commit }, payload) {
      await getExportClientsAdvertisers(payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },

    //SalesPlanExecutions
    async GET_REPORT_SALES_PLAN_EXECUTIONS({ dispatch, commit }, payload) {
      commit('setReportSalesPlanExecutionsRequest');
      await getReportSalesPlanExecutions(payload)
        .then((res) => {
          commit('setReportSalesPlanExecutionsSuccess', res.data);
        })
        .catch((e) => {
          commit('setReportSalesPlanExecutionsError');
        });
    },
    async GET_EXPORT_REPORT_SALES_PLAN_EXECUTIONS({ dispatch, commit }, payload) {
      await getExportSalesPlanExecutions(payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
    async PUT_CALENDAR_PLAN({ dispatch, commit }, payload) {
      await putCalendarPlans(payload.formData)
        .then((res) => payload.handler(res))
        .catch((e) => payload.handlerError(e));
    },

    //SalesPlanExecutionsTVNetwork
    async GET_REPORT_SALES_PLAN_EXECUTIONS_TV_NETWORK({ dispatch, commit }, payload) {
      commit('setReportSalesPlanExecutionsTVNetworkRequest');
      await getReportSalesPlanExecutionsTVNetwork(payload)
        .then((res) => {
          commit('setReportSalesPlanExecutionsTVNetworkSuccess', res.data);
        })
        .catch((e) => {
          commit('setReportSalesPlanExecutionsTVNetworkError');
        });
    },
    async GET_EXPORT_REPORT_SALES_PLAN_EXECUTIONS_TV_NETWORK({ dispatch, commit }, payload) {
      await getExportSalesPlanExecutionsTVNetwork(payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
    // async PUT_CALENDAR_PLAN_TV_NETWORK({ dispatch, commit }, payload) {
    //   await putCalendarPlans(payload.formData)
    //     .then((res) => payload.handler(res))
    //     .catch((e) => payload.handlerError(e));
    // },

    //Inventory
    async GET_REPORT_INVENTORY({ dispatch, commit }, payload) {
      commit('setReportInventoryRequest');
      await getReportInventory(payload)
        .then((res) => commit('setReportInventorySuccess', res.data.data))
        .catch((e) => commit('setReportInventoryError'));
    },
    async GET_EXPORT_REPORT_INVENTORY({ dispatch, commit }, payload) {
      await getExportInventory(payload.data)
        .then((res) => payload.handler(res))
        .catch((e) => {});
    },

    //By Projects
    async GET_REPORT_PROJECTS({ dispatch, commit }, payload) {
      commit('setReportByProjectsRequest');
      await getReportByProjects(payload)
        .then((res) => commit('setReportByProjectsSuccess', res.data.data))
        .catch((e) => commit('setReportByProjectsError'));
    },
    async GET_EXPORT_REPORT_PROJECTS({ dispatch, commit }, payload) {
      await getExportByProjects(payload.data)
        .then((res) => payload.handler(res))
        .catch((e) => {});
    },

    //By Projects (short)
    async GET_REPORT_PROJECTS_SHORT({ dispatch, commit }, payload) {
      commit('setReportByProjectsShortRequest');
      await getReportByProjectsShort(payload)
        .then((res) => commit('setReportByProjectsShortSuccess', res.data.data))
        .catch((e) => commit('setReportByProjectsShortError'));
    },
    async GET_EXPORT_REPORT_PROJECTS_SHORT({ dispatch, commit }, payload) {
      await getExportByProjectsShort(payload.data)
        .then((res) => payload.handler(res))
        .catch((e) => {});
    },

    //Programs
    async GET_REPORT_PROGRAMS({ dispatch, commit }, payload) {
      commit('setReportProgramsRequest');
      await getReportPrograms(payload)
        .then((res) => commit('setReportProgramsSuccess', res.data.data))
        .catch((e) => commit('setReportProgramsError'));
    },
    async GET_EXPORT_REPORT_PROGRAMS({ dispatch, commit }, payload) {
      await getExportPrograms(payload.data)
        .then((res) => payload.handler(res))
        .catch((e) => {});
    },

    //Inventory Extended
    async GET_REPORT_INVENTORY_EXTENDED({ dispatch, commit }, payload) {
      commit('setReportInventoryExtendedRequest');
      await getReportInventoryExtended(payload)
        .then((res) => commit('setReportInventoryExtendedSuccess', res.data.data))
        .catch((e) => commit('setReportInventoryExtendedError'));
    },
    async GET_EXPORT_REPORT_INVENTORY_EXTENDED({ dispatch, commit }, payload) {
      await getExportInventoryExtended(payload.data)
        .then((res) => payload.handler(res))
        .catch((e) => {});
    },

    //Category Summary
    async GET_REPORT_CATEGORY_SUMMARY({ dispatch, commit }, payload) {
      commit('setReportCategorySummaryRequest');
      await getReportCategorySummary(payload)
        .then((res) => commit('setReportCategorySummarySuccess', res.data.data))
        .catch((e) => commit('setReportCategorySummaryError'));
    },
    async GET_EXPORT_REPORT_CATEGORY_SUMMARY({ dispatch, commit }, payload) {
      await getExportCategorySummary(payload.data)
        .then((res) => payload.handler(res))
        .catch((e) => {});
    },

    //MMR Kazakhstan
    async GET_REPORT_MMR_KAZAKHSTAN({ dispatch, commit }, payload) {
      commit('setReportMMRKazakhstanRequest');
      await getReportMMRKazakhstan(payload)
        .then((res) => commit('setReportMMRKazakhstanSuccess', res.data.data))
        .catch((e) => commit('setReportMMRKazakhstanError'));
    },
    async GET_EXPORT_REPORT_MMR_KAZAKHSTAN({ dispatch, commit }, payload) {
      await getExportMMRKazakhstan(payload.data)
        .then((res) => payload.handler(res))
        .catch((e) => {});
    },
  },
  getters: {
    getReportCosts: (state) => state.report.costs,
    getReportCostsStatus: (state) => state.report.costsStatus,
    getReportBroadcastReference: (state) => state.report.broadcastReference,
    getReportBroadcastReferenceStatus: (state) => state.report.broadcastReferenceStatus,
    getReportObligationExecutions: (state) => state.report.obligationExecutions,
    getReportObligationExecutionsStatus: (state) => state.report.obligationExecutionsStatus,
    getReportClientsAdvertisers: (state) => state.report.clientsAdvertisers,
    getReportClientsAdvertisersStatus: (state) => state.report.clientsAdvertisersStatus,
    getReportSalesPlanExecutions: (state) => state.report.salesPlanExecutions,
    getReportSalesPlanExecutionsStatus: (state) => state.report.salesPlanExecutionsStatus,
    getReportSalesPlanExecutionsTVNetwork: (state) => state.report.salesPlanExecutionsTVNetwork,
    getReportSalesPlanExecutionsTVNetworkStatus: (state) => state.report.salesPlanExecutionsTVNetworkStatus,
    getReportInventory: (state) => state.report.inventory,
    getReportInventoryStatus: (state) => state.report.inventoryStatus,
    getReportPrograms: (state) => state.report.programs,
    getReportProgramsStatus: (state) => state.report.programsStatus,
    getReportByProjects: (state) => state.report.byProjects,
    getReportByProjectsStatus: (state) => state.report.byProjectsStatus,
    getReportByProjectsShort: (state) => state.report.byProjectsShort,
    getReportByProjectsShortStatus: (state) => state.report.byProjectsShortStatus,
    getExpandAllRows: (state) => state.expandAllRows,
    getReportInventoryExtended: (state) => state.report.inventoryExtended,
    getReportInventoryExtendedStatus: (state) => state.report.inventoryExtendedStatus,
    getReportCategorySummary: (state) => state.report.categorySummary,
    getReportCategorySummaryStatus: (state) => state.report.categorySummaryStatus,
    getReportMMRKazakhstan: (state) => state.report.mmrKazakhstan,
    getReportMMRKazakhstanStatus: (state) => state.report.mmrKazakhstanStatus,
  },
};
